import React from 'react'
import { graphql } from 'gatsby'
import { Flex, Heading, Grid, Link } from '@pw/ui'

import CategoryBox from '../components/Products/CategoryBox'
import { Layout, Container } from '../components/Layout'
import Section from '../components/Layout/Section'

export const ShopPage = ({ data, location }) => {
  const categories = data?.allContentfulProductCategory?.edges || []

  return (
    <Layout location={location}>
      <Section>
        <Flex justifyContent="center">
          <Heading
            as="h1"
            fontFamily="script"
            fontWeight="normal"
            textTransform="capitalize"
          >
            The Shop
          </Heading>
        </Flex>
      </Section>

      <Container px="20" py="10" maxWidth="1200px">
        <Grid
          templateColumns={{
            base: '1fr',
            sm: '1fr 1fr',
            md: `repeat(4, 1fr)`,
          }}
          gap={6}
          pt="5"
        >
          {categories.map(({ node }) => (
            <CategoryBox category={node} key={node.id} />
          ))}
        </Grid>
      </Container>
    </Layout>
  )
}

export default ShopPage

export const pageQuery = graphql`
  query ShopPage {
    allContentfulProductCategory {
      edges {
        node {
          ...productCategoryFields
        }
      }
    }
  }
`
