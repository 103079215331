import React from 'react'
import Img from 'gatsby-image'
import { Box, PseudoBox, Link, Heading } from '@pw/ui'

import './fragments'

// const ProductCategoryBox = ({ category, ...props }) => {
//   const mainPhoto = category?.mainPhoto?.fluid || false

//   return (
//     <PseudoBox {...props}>
//       <Image
//         src={mainPhoto?.src}
//         fallbackSrc="https://via.placeholder.com/200"
//       />
//     </PseudoBox>
//   )
// }

const ProductCategoryBox = ({
  category,
  data = {},
  displayType = 'default',
  ...props
}) => {
  const mainPhoto = category?.mainPhoto?.fluid || false

  return (
    <PseudoBox
      as={Link}
      to={`/shop/${category.slug}`}
      bg="pink.200"
      display="block"
      _hover={{
        ...(props._hover || {}),
        display: 'block',
        bg: 'pink.300',
        color: 'pink.50',
      }}
      {...props}
    >
      <Box position="relative" bg="pink.50">
        {mainPhoto && <Img fluid={mainPhoto} width="100%" />}
      </Box>

      <Heading as="h6" p="4" color="white" textAlign="center">
        {category.name}
      </Heading>
    </PseudoBox>
  )
}

export default ProductCategoryBox
